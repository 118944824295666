// import { currencyFormat } from '../utils/numberFormat'
export class amortizationAccount {
  constructor(account) {
    this.title = account.title
    this.loanAmount = parseFloat(account.loanAmount)
    this.monthlyPayment = parseFloat(account.monthlyPayment)
    this.termInMonths = parseInt(account.termInMonths)
    this.monthsLeft = parseInt(account.monthsLeft)
    this.interestRate = parseFloat(account.interestRate) / 100
  }

  get data() {
    return {
      title: this.title,
      loanAmount: this.loanAmount,
      interestRate: this.interestRate,
      interestRemaining: this.calculateRemainingInterest(this.monthsLeft),
      principalRemaining: this.calculateRemainingPrincipal(this.monthsLeft)
    }
  }

  get amortizationSchedule() {
    if (this.termInMonths !== this.monthsLeft) {
      let result = this.createAmortizationSchedule()
      return result.slice(result.length - this.monthsLeft - 1)
    } else {
      return this.createAmortizationSchedule()
    }
  }

  createAmortizationSchedule() {
    let result = []
    result.push({ number: 0, title: this.title, interest: this.interestRate, outstandingBalance: this.loanAmount })
    for (let i = 0; i < this.termInMonths; i++) {
      //
      let payment = this.monthlyPayment
      let interest = result[i].outstandingBalance * (this.interestRate / 12)
      let appliedToPrincipal = payment - interest
      let outstandingBalance = result[i].outstandingBalance - appliedToPrincipal
      result.push({
        number: i + 1,
        payment: payment,
        interest: interest,
        appliedToPrincipal: appliedToPrincipal,
        outstandingBalance: outstandingBalance
      })
    }
    return result
  }

  calculateRemainingInterest() {
    let result = this.amortizationSchedule.map((item) => item.interest).reduce((prev, curr) => prev + curr, 0)
    return result
  }

  calculateRemainingPrincipal() {
    let result = this.amortizationSchedule[this.amortizationSchedule.length - 1 - this.monthsLeft].outstandingBalance
    return result
  }
}
