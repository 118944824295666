<template>
  <div>
    <v-row>
      <v-col>
        <v-simple-table v-if="loading" dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Account</th>
                <th>Loan Amount</th>
                <th>Monthly Payment</th>
                <th>Term in Months</th>
                <th>Months Left</th>
                <th>Interest Rate</th>
                <th colspan="2" class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(account, index) in accounts" :key="`acct_${index}`">
                <td><v-text-field v-model="account.title" outlined dense hide-details /></td>
                <td><v-text-field v-model="account.loanAmount" outlined dense hide-details type="number" /></td>
                <td><v-text-field v-model="account.monthlyPayment" outlined dense hide-details type="number" /></td>
                <td><v-text-field v-model="account.termInMonths" outlined dense hide-details type="number" /></td>
                <td><v-text-field v-model="account.monthsLeft" outlined dense hide-details type="number" :max="account.termInMonths" /></td>
                <td><v-text-field v-model="account.interestRate" outlined dense hide-details type="number" /></td>
                <td>
                  <v-switch v-model="account.creditCard" append-icon="mdi-credit-card-outline"></v-switch>
                </td>
                <td class="text-right">
                  <v-btn icon color="red" @click="removeAccount(index)"><v-icon>mdi-minus-circle-outline</v-icon></v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-container>
          <v-row>
            <v-col class="text-right">
              Add account... <v-btn icon color="primary" @click="addAccount()"> <v-icon>mdi-plus-circle-outline</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Account</th>
                <th>Loan Amount</th>
                <th>Interest Rate</th>
                <th>Remaining Interest</th>
                <th>Remaining Principal</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(account, index) in calculated" :key="`calc_${index}`">
                <td class="font-weight-bold">{{ account.title }}</td>
                <td>{{ currencyFormat(account.loanAmount) }}</td>
                <td>{{ account.interestRate }}</td>
                <td>{{ currencyFormat(account.interestRemaining) }}</td>
                <td>{{ currencyFormat(account.principalRemaining) }}</td>
              </tr>
              <!-- <tr>
                <td colspan="3">{{ account.schedule[0].number }}</td>
                <td>{{ currencyFormat(account.interestRemaining) }}</td>
                <td>{{ currencyFormat(account.schedule[0].outstandingBalance) }}</td>
              </tr>
              <tr v-for="(item, index) in account.schedule" :key="`calc_${index}`">
                <td>{{ item.number }}</td>
                <td>{{ currencyFormat(item.payment) }}</td>
                <td>{{ currencyFormat(item.interest) }}</td>
                <td>{{ currencyFormat(item.appliedToPrincipal) }}</td>
                <td>{{ currencyFormat(item.outstandingBalance) }}</td>
              </tr> -->
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { amortizationAccount } from '../../shared/classes/calculators'
import numberFormat from '../../shared/mixins/numberFormat'
export default {
  name: 'CalculatorSnowball',
  mixins: [numberFormat],
  data() {
    return {
      accounts: [
        {
          creditCard: false,
          interestRate: 1.79,
          loanAmount: 10000,
          monthlyPayment: 841.48,
          monthsLeft: 12,
          termInMonths: 12,
          title: 'test'
        },
        {
          creditCard: false,
          interestRate: 9.99,
          loanAmount: 29761.43,
          monthlyPayment: 365.38,
          monthsLeft: 105,
          termInMonths: 144,
          title: 'Greensky Roof'
        }
      ],
      schedules: [],
      loading: false
    }
  },
  computed: {
    calculated() {
      let result = []
      this.accounts.forEach((account) => {
        if (!account.creditCard && account.interestRate) {
          result.push(this.amortize(account))
        }
      })
      if (result.length > 0) {
        return result
      } else {
        return []
      }
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = false
      await this.addAccount()
      this.loading = true
    },
    addAccount() {
      let newAccount = {
        title: ''
      }
      this.accounts.push(newAccount)
    },
    removeAccount(index) {
      this.accounts.splice(index, 1)
    },
    amortize(data) {
      let result = new amortizationAccount(data)
      this.schedules.push(result.amortizationSchedule)
      return result.data
    }
  }
}
</script>

<style></style>
